import FindUsWrapper from 'components/find-us/findUsWrapper'
import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import React from 'react'
import styled from 'styled-components'
import {media} from 'utils/Media'

const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40rem;
  z-index: 2;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 100;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 100;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media ${media.md} {
    height: 30rem;
  }
  @media ${media.xl} {
    height: 40rem;
  }
`

const FindUs = () => {
    const meta = {
        title: 'Find Us | The Mousetrap',
    }
    return (
        <Layout title={meta.title}>
            <MaxWidth $wooden>
                <FindUsWrapper/>
            </MaxWidth>
        </Layout>
    )
}

export default FindUs
